:root {
  /* LIGHT */
  --primary-color: #f9f9ff;
  --secondary-color: #252739;
  --third-color: #ffd15c;
  --headings-text-color: #454360;
  --paragraph-text-color: #8b88b1;
  --ppp-text-color: #5e5c7f;
  --red-text-color: #ff4c60;
  --placeholder-text-color: #aeacca;
  --x: #44D7B6;

  /* DARK */
  --dark-color: #252739;
  --box-dark-color: #344966;
  --text-color: #fff;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--dark-color);
}

// GENERAR

.rounded {
  border-radius: 20px !important;
}

.box-dark {
  background-color: var(--box-dark-color);
}

.box-white {
  background-color: white;
}

.text-dark {
  color: var(--text-dark-color);
}

.text-white {
  color: #fff;
}

.shadow-dark {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  -moz-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.shadow-light {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
  -moz-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
}

.shadow-pink {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
}

.shadow-yellow {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
}

.padding-30 {
  padding: 30px;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.section-margins{
  margin-bottom: 4rem;
}

// MAIN
main.content {
  margin-left: 290px;
}

@media only screen and (max-width: 991px) {
  main.content {
    margin-left: 0;
  }
}

// HEADER
header.desktop-header {
  color: #fff;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  background: $colordark;
  padding: 40px 40px 20px 40px;
  position: fixed;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  width: 290px;
  z-index: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  @include transition(0s);

  &.open {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    z-index: 2;
  }

  .site-logo {}

  /* === Vertical Menu === */
  .vertical-menu {
    list-style: none;
    padding: 0;
    margin-top: 100px;
    margin-bottom: 0;
  }

  .vertical-menu li.openmenu>a {
    color: #000;
  }

  .vertical-menu li.openmenu .switch {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .vertical-menu li {
    padding: 8px 0;
    position: relative;
    list-style: none;
    @include transition(0.2s);

    a {
      &:hover {
        color: $coloryellow;

        i {
          color: $coloryellow;
          opacity: 1;
        }
      }
    }

    i {
      color: #fff;
      margin-right: 20px;
      opacity: 0.4;
      @include transition(0.3s);
    }
  }

  .vertical-menu li a {
    color: #fff;
    font-family: $fonthead;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    &.nav-link {
      display: initial;
      padding: 0;
    }
  }

  .vertical-menu li .nav-link.active {
    color: $coloryellow;

    i {
      color: $coloryellow;
      opacity: 1;
    }
  }

  .footer {
    margin-top: auto;
    position: relative;
  }

  .copyright {
    color: #9c9ab3;
    font-size: 14px;
    white-space: nowrap;
    display: inline-block;
    overflow: visible;
  }

  &.light {
    background: #f9f9ff;
    border-right: solid 1px rgba(0, 0, 0, 0.05);

    .vertical-menu {
      li {
        i {
          color: $colordark;
          opacity: 1;
        }
      }
    }
  }
}

header.mobile-header {
  background: $colordark;
  display: none;
  padding: 10px 0;
  @include transition(0s);

  &.open {
    -webkit-transform: translateX(290px);
    -moz-transform: translateX(290px);
    -ms-transform: translateX(290px);
    -o-transform: translateX(290px);
    transform: translateX(290px);
  }

  .menu-icon {
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      outline: 0;
      height: 33px;
      width: 33px;
    }

    span {
      display: block;
      background: #fff;
      border-radius: 5px;
      height: 4px;
      position: relative;
      width: 30px;

      &:before {
        content: "";
        display: block;
        background: #fff;
        border-radius: 5px;
        height: 4px;
        top: -10px;
        position: absolute;
        width: 30px;
      }

      &:after {
        content: "";
        display: block;
        background: #fff;
        border-radius: 5px;
        height: 4px;
        top: 10px;
        position: absolute;
        width: 30px;
      }
    }
  }

  .site-logo {
    display: inline-block;

    img {
      max-height: 30px;
    }
  }

  &.light {
    background: #f9f9ff;

    .menu-icon span {
      background: $colordark;

      &:before,
      &:after {
        background: $colordark;
      }
    }
  }
}

@media only screen and (max-height: 667px) {
  header.desktop-header .footer {
    margin-top: auto;
  }
}

@media only screen and (max-width: 991px) {
  header.desktop-header {
    -webkit-transform: translateX(-290px);
    -moz-transform: translateX(-290px);
    -ms-transform: translateX(-290px);
    -o-transform: translateX(-290px);
    transform: translateX(-290px);
  }

  header.mobile-header {
    border-bottom: 1px solid #ffffff01;
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }
}

.section-title {
  font-size: 36px;
  margin: 0;
  margin-left: 14px;
  position: relative;

  &:before {
    content: "";
    background-image: url("../images/dots-bg.svg");
    display: block;
    height: 37px;
    left: -14px;
    top: -14px;
    position: absolute;
    width: 37px;
  }
}

// ABOUT

#section-about section .container:first-child {
  margin-top: 30px;
}
@media (max-width: 992px){
  #section-about section .container:first-child{
    margin-top: 56px;
  }
}

.skill-item {
  border-radius: 31px;
  padding: 12px 32px;

  .skill-info {
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0 !important;
    }

    //   span {
    //     font-size: 14px;
    //   }
  }
}

.fact-item {
  display: flex;

  .details {
    margin-left: 20px;
    display: flex;

    p {
      align-self: center;
      margin-left: 12px;
    }
  }

  .icon {
    font-size: 36px;
    color: #5e5c7f;
    float: left;
  }

  .number {
    font-size: 30px;

    em {
      font-style: normal;
    }
  }

  p {
    font-size: 16px;
  }
}

// EXPERIENCES
#section-experiences {
  &:target{
    scroll-margin-top: 100px;
  }
}
.timeline {
  position: relative;

  .content p {
    margin: 0 0 10px 0;
    font-size: 14px;
    color: #ffffffaa;
  }
}

.timeline .timeline-container {
  padding-left: 50px;
  margin-bottom: 0px;
  position: relative;
  background-color: inherit;
  width: 100%;
}

.timeline.edu .timeline-container::after {
  content: "\e019";
  background: var(--box-dark-color);
  font-family: "simple-line-icons";
  font-size: 24px;
  font-weight: 700;
  color: #457B9D;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline.exp .timeline-container::after {
  content: "\e04b";
  background: var(--box-dark-color);
  font-family: "simple-line-icons";
  font-size: 24px;
  font-weight: 700;
  color: #457B9D;  
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline .content {
  position: relative;
}

.timeline .content .time {
  color: #8b88b1;
  font-size: 14px;
}

.timeline .content h3 {
  font-size: 20px;
  margin: 0;
}

.timeline .content p {
  margin: 0 0 10px 0;
}

.timeline span.line {
  position: absolute;
  width: 1px;
  background-color: #457B9D;
  top: 30px;
  bottom: 30px;
  left: 34px;
}

.timeline span.dot {
  width: 6px;
  position: absolute;
  background-color: #457B9D;
  bottom: 30px;
  left: 31px;
  height: 6px;
  border-radius: 50%;
}

// BLOG
.blog-item {
  overflow: hidden;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  @include transition(0.3s);

  &:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(-10px);
  }

  .thumb {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      @include transition(0.3s);
      height: 100%;
    }

    &:hover {
      img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .category {
    background: $colorpink;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    font-size: 14px;
    padding: 2px 8px;
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 1;
  }

  .details {
    padding: 20px;
    background-color: #302f4e;

    h4.title {
      font-size: 20px;

      a {
        color: var(--text-color);
        text-decoration: none;

        &:hover {
          color: $colorpink;
        }
      }
    }
  }

  .meta {
    font-size: 14px;
    color: #8b88b1;

    li:not(:last-child) {
      margin-right: 0.6rem;
    }

    li {
      &:after {
        content: "";
        background: #8b88b1;
        border-radius: 50%;
        display: inline-block;
        height: 3px;
        margin-left: 0.6rem;
        vertical-align: middle;
        width: 3px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
  .post-category{
    cursor: default;
  }
}

.blog-wrapper {
  .thumb {
    height: 200px;
    justify-content: center;
    display: flex;
  }

  .details {
    height: 145px;
  }
}

@media only screen and (max-width: 768px) {
  .blog-wrapper {
    margin: -20px 0;

    .blog-item {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .blog-item {
    max-width: 360px;
    margin: auto;
  }
}

.blog-page-section{
  margin-top: 100px;
  margin-bottom: 50px;
}

// CONTACT
.contact-info .lets-talk {
  background-image: url("../images/map/map-light.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;

  h3 {
    font-size: 23px;
    margin: 0 0 10px;
  }
}

/* === Social Icons === */
.social-icons {
  li {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    a {
      color: white;
      font-size: 21px;
    }
  }

  &.light {
    li {
      a:hover {
        color: $coloryellow;
      }
    }
  }
}

/* === Form === */
@media (max-width: 576px){
  .contact-info .contact-form{
    margin-top: -90px;
  }
}

.form-control {
  display: block;
  border-radius: 30px;
  width: 100%;
  height: 54px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: $colordefault;
  background-color: #fff;
  background-image: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  @extend .shadow-dark;
}

.form-control:focus {
  @extend .shadow-dark;
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #aeacca;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeacca;
}

.form-control::placeholder {
  color: #aeacca;
}

.form-group {
  margin-bottom: 30px;

  textarea {
    min-height: 80px;
    max-height: 150px;
  }
}

.skills {
  box-sizing: border-box;
  width: 300px;
  height: 300px;
  margin: auto;
  padding: 18px 20px;
  top: 0;

  h4 {
    font-size: 24px;

    svg {
      margin-left: 8px;
      height: 24px;

      path {
        fill: yellow;
      }
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;
      margin-left: 0;
      font-size: 19px;
    }
  }
}

.skills-background {
  box-sizing: border-box;
  background-color: #ffffffaa;
  width: 300px;
  height: 300px;
  margin: auto;
  transform: rotate(8deg);
  margin-left: 18px;
}